<header1></header1>
<div class="page-body">
    <div class="page-container-medium">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title>
                    <mat-icon>shopping_cart</mat-icon>
                    GemLPB
                </mat-card-title>
                <mat-card-subtitle>
                    LPB-Format Checker/Viewer (Desktop Tool)
                </mat-card-subtitle>
                <mat-card-content>
                    <div style="padding:3px;">
                        <div class="prodimage">
                            <div>
                                <img style="width:100%" src="./assets/gemlpb_gview.png">
                            </div>
                            <div>
                                <span class="mat-caption">GemLPB tool image, viewing a LPB-G format file</span>
                            </div>
                        </div>
                        <div class="prodexplain">
                            <p>
                                GemLPB is a Windows desktop tool that provides the following functions.
                            </p>
                            <ul>
                                <li>LPB-C/N/G Format Checking</li>
                                <li>LPB-G Format Viewing</li>
                            </ul>
                            <p>
                                These functions are basically the same as this site (gem-lpb.com)
                                offers on-line for free.
                                If you cannot upload your files,
                                you can download the desktop tool to check your files locally.
                                It runs on Windows 10 64-bit computers with at least 8GB of memory.
                            </p>
                        </div>
                    </div>
                    <div style="padding:3px;">
                        <h3>
                            Download
                        </h3>
                        <div style="margin-left:2rem">
                            <div>
                                <button mat-raised-button [disabled]="!agreed.checked" color="primary"
                                    style="width:90%" (click)="downloadGemLMD()">
                                    Download GemLPB 1.4.11 (63MB, for Windows 10/11 64-bit) <!-- modify here when version up -->
                                </button>
                                <div style="display:none">
                                    <a id="hidden" href="assets/gemlpb_1.4.11_download.zip">Download</a> <!-- modify here when version up -->
                                </div>
                            </div>
                            <div>
                                <mat-checkbox #agreed style="margin-left:10rem;">
                                    Concent to <a href="assets/gemlpb_ltu_latest.txt"> GemLPB License Agreement</a>
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div style="padding:3px;">
                        <h3>
                            License Plan
                            <span class="mat-caption">(w/o tax, per 1 license)</span>
                        </h3>
                        <div style="margin-left:2rem;">
                            <table mat-table [dataSource] = "gemlpbLicPlans" class="mat-elevation-z8">
                                <ng-container matColumnDef="planDescr">
                                    <mat-radio-group [(ngModel)]="seldLicPlan" >
                                        <td mat-cell *matCellDef="let plan;">
                                            <mat-radio-button [value]="plan">
                                                {{plan.planDescr}}
                                            </mat-radio-button>
                                        </td>
                                    </mat-radio-group>
                                </ng-container>
                                <ng-container matColumnDef="priceDescr">
                                    <td mat-cell *matCellDef="let plan;">
                                        {{plan.priceDescr}}
                                    </td>
                                </ng-container>
                                <tr mat-row *matRowDef="let row; columns: gemlpbLicPlanTableCols;"></tr>"
                            </table>
                        </div>
                    </div>
                    <div style="padding:3px;">
                        <h3>
                            License Count
                        </h3>
                        <div style="margin-left:2rem;">
                            <mat-form-field style="width:80%;">
                                <mat-label>License Count</mat-label>
                                <input matInput type="number" [(ngModel)]="lic_count">
                            </mat-form-field>
                        </div>
                    </div>
                    <div style="padding:3px;">
                        <h3>
                            Licensee (Your Company Name)
                        </h3>
                        <div style="margin-left:2rem;">
                            <table mat-table [dataSource] = "licensees" class="mat-elevation-z8 licenseetbl">
                                <ng-container matColumnDef="licensee">
                                    <mat-radio-group [(ngModel)]="seldLicensee" >
                                        <td mat-cell *matCellDef="let licensee;">
                                            <mat-radio-button [value]="licensee" [checked]="licensee===seldLicensee">
                                                {{licensee}}
                                            </mat-radio-button>
                                        </td>
                                    </mat-radio-group>
                                </ng-container>
                                <tr mat-row *matRowDef="let row; columns: licenseeTableCols;"></tr>
                            </table>
                            <div *ngIf="seldLicensee===new_licensee">
                                <mat-form-field style="width:80%; margin-top:0.5em;">
                                    <textarea type="text" matInput placeholder="Licensee Name"
                                        [(ngModel)]="new_licensee_name">
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div style="padding:3px; margin-top:1rem;">
                        <div style="margin-left:2rem">
                            <button mat-raised-button [disabled]="!seldLicPlan" color="primary" (click)="contactUsPressed()">
                                Contact Gem Design Tech
                            </button>
                            <div style="margin-left:2em;">
                                <p>
                                    The above button will send an email from the system to you and to us.
                                    We will respond to you asap, so that you can place a purchase order.
                                </p>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
