import Flatten from "@flatten-js/core";
export interface GemPointI{
    x:number;
    y:number;
}
export interface GemBboxI{
	xmin:number;
	ymin:number;
	xmax:number;
	ymax:number;
}
export interface GemShapeI{
	lineno:number;
  classname:string;
  id:string; // shape id
  fig:string; // keyword that describes the shape
  isvoid:boolean; // is hole shape. in G-format, hole shapes and outline shapes are independent.
  layer:number; // 1,2,.. is set when instantiated.
}
export interface GemMoveToI{
	lineno:number;
  classname: string;
  p:GemPointI;
}
export interface GemArcToI extends GemMoveToI{
  ac:GemPointI; // arc center
  isccw:boolean; // rarc is ccw
}
export interface GemArc3ToI extends GemMoveToI{
  mp:GemPointI; // middle-point
}
export interface GemPolyI extends GemShapeI{
  isclosed:boolean; // implicit line segment from the last point to the first point
  pps:GemMoveToI[];
	ape:number; // must be 0 if closed.
}
export interface GemCircleI extends GemShapeI{
  cen:GemPointI;
  dia:number;
  innerdia:number; // donut shape
}
export interface GemTextI extends GemShapeI{
	pnt:GemPointI;
	text:string;
	charheight:number;
	charwidth:number;
	justification:string; // 'LEFT'/'RIGHT'/'CENTER'
}
/**
 * point on 2d plane.
 */
export class GemPoint implements GemPointI{
	x:number = 0;
	y:number = 0;
	private static rotateWk:GemPoint;
	private static _minus = new GemPoint(0,0);
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	static fromJSON(json:string):GemPoint{
		const obj:GemPointI = JSON.parse(json);
		return new GemPoint(0,0).fromI(obj);
	}
	// flattenPointI(){
	// 	return new Flatten.Point(this.x,this.y);
	// }
	toI():GemPointI{
		return {
			x: this.x,
			y: this.y
		}
    }
    fromI(ref:GemPointI):GemPoint{
        return this.copy(ref);
    }
	// constructor(...args){
	// 	if (args.length===0) return;
	// 	if (args.length==1 && args[0] instanceof GemPoint){
	// 		this.copy(args[0]);
	// 	}else if (args.length==2 && typeof args[0] === 'number' && typeof args[1] === 'number'){
	// 		this.x = args[0];
	// 		this.y = args[1];
	// 	}
	// }
	constructor(x:number, y:number){
		this.x = x;
		this.y = y;
	}
	toString():string{
		return "("+this.x.toFixed(2)+", "+this.y.toFixed(2)+")";
	}
	moveRatio(target:GemPoint, ratio:number):GemPoint{
		this.x = this.x*(1-ratio) + target.x*ratio;
		this.y = this.y*(1-ratio) + target.y*ratio;
		return this;
	}
	movePolar(deg:number,dist:number):GemPoint{
		const rad = deg/180*Math.PI;
		return this.move(dist*Math.cos(rad),dist*Math.sin(rad));
	}
	equals(that:GemPoint):boolean{
		return this.x===that.x && this.y===that.y;
		// (js note) javascript does not allow operator override.
		// then you need to do p1.equals(p2), instead of p1===p2, to call this.
	}
	minus(that:GemPoint):GemPoint{
		GemPoint._minus.x = this.x - that.x;
		GemPoint._minus.y = this.y - that.y;
		return GemPoint._minus;
	}
	setXY(x:number, y:number):GemPoint{
		this.x = x;
		this.y = y;
		return this;
	}
	copy(that:GemPointI):GemPoint{
		this.x = that.x;
		this.y = that.y;
		return this;
	}
	mkcopy():GemPoint{
		var that = new GemPoint(this.x,this.y);
		return that.copy(this);
	}
	move(dx:number, dy:number):GemPoint{
		this.x += dx;
		this.y += dy;
		return this;
	}
	// setMiddlePoint(p1:Point, p2:Point):Point{
	// 	return this.setXY((p1.x + p2.x)/2,(p1.y + p2.y)/2);
	// }
	rotate(degree:number, rotcen:GemPoint):GemPoint{
		return this.copy(GemPoint.rotate_xy(this.x,this.y,degree,rotcen));
	}
	private static rotate_xy(x:number,y:number,degree:number,rotcen:GemPoint):GemPoint{
		while(degree < 0) degree += 360;
		while(degree >= 360) degree -= 360;
		if (!this.rotateWk) this.rotateWk = new GemPoint(0,0);
		if ((degree%90)===0){
			var idx = (degree / 90 + 4) % 4;
			const conv:number[][] = [[1,0,0,1],[0,-1,1,0],[-1,0,0,-1],[0,1,-1,0]];
			var dx = x - rotcen.x, dy = y - rotcen.y;
			this.rotateWk.x = conv[idx][0] * dx + conv[idx][1] * dy;
			this.rotateWk.y = conv[idx][2] * dx + conv[idx][3] * dy;
			this.rotateWk.move(rotcen.x,rotcen.y);
		}else{
			var radian = degree / 180.0 * Math.PI;
			var cosx = Math.cos(radian);
			var sinx = Math.sin(radian);
			var dx = x - rotcen.x;
			var dy = y - rotcen.y;
			this.rotateWk.x = cosx * dx - sinx * dy;
			this.rotateWk.y = sinx * dx + cosx * dy;
			this.rotateWk.move(rotcen.x,rotcen.y);
		}
		return this.rotateWk;
	}
	flip(x0:number):GemPoint{
		this.x = x0 - (this.x - x0);
		return this;
	}
	setPolar(p1:GemPoint, angdeg:number, dist:number):GemPoint{
		this.setXY(p1.x+Math.cos(angdeg/180*Math.PI)*dist,p1.y+Math.sin(angdeg/180*Math.PI)*dist);
		return this;
	}
}
export class GemEuclid{
	static xpnt:GemPoint = new GemPoint(0,0);
	static xp1:GemPoint = new GemPoint(0,0);
	static xp2:GemPoint = new GemPoint(0,0);
	static measuredPoint = new GemPoint(0,0);
	static timeOfScan:number = 0.0;
	static travelLength:number = 0.0;
	static dist(p1:GemPoint, p2:GemPoint):number{
		var dx = p2.x - p1.x;
		var dy = p2.y - p1.y;
		return Math.sqrt(dx*dx+dy*dy);
	}
	/**
	 * @returns [0,360) value
	 */
	static degreeModulo(degree:number):number{
		return GemEuclid.modulo(degree,360);
	}
	/**
	 * @returns [0,rule) value
	 */
	static modulo(val:number, rule:number):number{
		while(val < 0) val += rule;
		while(val >= rule) val -= rule;
		return val;
	}
	/**
	 * returns the angle of vector (p1,p2) in [0,360) range.
	 */
	static angdeg(p1:GemPoint, p2:GemPoint):number{
	    return GemEuclid.degreeModulo(Math.atan2(p2.y-p1.y,p2.x-p1.x)/Math.PI*180.0);
	}
	/**
	 * returns the difference of angles dig2-dig1 in [0,360) range.
	 */
	private static degreeDiff(degree1:number, degree2:number):number{
		return GemEuclid.degreeModulo(GemEuclid.degreeModulo(degree2)-GemEuclid.degreeModulo(degree1));
	}
	/**
	 * returns the difference of angles dig2-dig1 in (-180,180] range.
	 */
	static degreeDiff180(degree1:number, degree2:number):number{
		var deg = GemEuclid.degreeDiff(degree1,degree2);
		return (deg<=180)? deg: -(360-deg);
	}
	/**
	 * returns if a trip p1,p2,p3 turns left/straight/right at p2, as 1/0/-1.
	 */
	static ccw(p1:GemPoint, p2:GemPoint, p3:GemPoint):number{
		const p2x = p2.x-p1.x;
		const p2y = p2.y-p1.y;
		const p3x = p3.x-p1.x;
		const p3y = p3.y-p1.y;
		const i1 = p2x*p3y;
		const i2 = p2y*p3x;
		const i = i1-i2;
		const rc3 = (i>0)?1:(i<0)?-1:0;
		return rc3;
	}
	static distPointLineSeg(p1:GemPoint,q1:GemPoint, q2:GemPoint):number{
		let d = GemEuclid.distPointHalfOpenLine(p1,q1,q2);
		if (GemEuclid.timeOfScan>1.0){
			d = GemEuclid.dist(p1,q2);
			GemEuclid.travelLength = GemEuclid.dist(q1,GemEuclid.measuredPoint);
			GemEuclid.measuredPoint.copy(q2);
		}
		return d;
	}
	static distPointHalfOpenLine(p1:GemPoint, q1:GemPoint, q2:GemPoint):number{
		if (q1.equals(q2)){
			GemEuclid.measuredPoint.copy(q1);
			GemEuclid.timeOfScan = 0.0;
			GemEuclid.travelLength = 0.0;
			return GemEuclid.dist(p1,q1);
		}
		/**
		 * let D be the square of the distance from p1 to a pebble that starts at q1 at time t = 0.0,
		 * moves toward q2 and reaches q2 at t=1.0. then, D is represented as follows.
		 *     D = ((q1x + t * dx) - p1x)^2 + ((q1y + t * dy) - p1y)^2
		 * where, dx = q2x - q1x, dy = q2y - q1y.
		 * then, the t value that leads minimum D value can be obtained by solving dD/dt == 0.
		 *                (q1x-p1x)*dx + (q1y-p1y)*dy
		 *    tmin =  -  ----------------------------
		 *                 dx^2 + dy^2
		 * then,
		 *   0 < tmin < 1.0 <==> the nearest point on the line is at interior part of the line seg.
		 **/
		const dx = q2.x - q1.x;
		const dy = q2.y - q1.y;
		const tmin = -((q1.x-p1.x)*dx + (q1.y - p1.y) * dy)/(dx*dx+dy*dy);
		GemEuclid.timeOfScan = tmin;
		let rval = 0;
		if (tmin <= 0.0){
				rval = GemEuclid.dist(p1,q1);
				GemEuclid.measuredPoint.copy(q1);
		}else if (tmin >= 1.0){
				rval = GemEuclid.dist(p1,q2);
				GemEuclid.measuredPoint.copy(q2);
		}else{
				rval = GemEuclid.distPointOpenLine(p1,q1,q2);
				GemEuclid.measuredPoint.setPolar(q1,GemEuclid.angdeg(q1,q2),tmin * GemEuclid.dist(q1,q2));
		}
		GemEuclid.travelLength = GemEuclid.dist(q1,GemEuclid.measuredPoint);
		return rval;
	}
	static distPointOpenLine(p1:GemPoint, q1:GemPoint, q2:GemPoint):number{
		return Math.abs(GemEuclid.distPointOpenLineSigned(p1,q1,q2));
	}
	static distPointOpenLineSigned(p1:GemPoint, q1:GemPoint, q2:GemPoint):number{
		if (q1.equals(q2)){
			GemEuclid.measuredPoint.copy(q1);
			return GemEuclid.dist(p1,q1);
		}
		/**
		 * the distance between point (x3,y3) and line 'ax+by+c==0' can be solved as follows.
		 *            (ax3 + by3 + c)^2
		 *    H^2 = -----------------------
		 *                a^2 + b^2 
		 * for a,b and c, following theorem holds.
		 * if line 'ax+by+c==0' passes (x1,y1) and (x2,y2), then
		 *    a/c = (y1-y2)/(x1y2-x2y1)
		 *    b/c = (x2-x1)/(x1y2-x2y1)
		 **/
		const x1 = q1.x; // 1
		const y1 = q1.y; // -1
		const x2 = q2.x; // 1
		const y2 = q2.y; // 1
		const x3 = p1.x; // 0
		const y3 = p1.y; // 0
		const d = (x1 * y2 - x2 * y1); // 1*1 - 1*(-1) = 2
		const dx = x2-x1; // 1-1=0
		const dy = y2-y1; // 1-(-1)=2
		const signedDist = (-dy*x3 + dx*y3 + d) / Math.sqrt(dx * dx + dy * dy); // 2/2=1
		const ang = GemEuclid.angdeg(q1,q2) - 90; // 0
		GemEuclid.measuredPoint.setPolar(p1,ang,signedDist); // (1,0)
		return signedDist; // 1
	}
	/**
	 * returns if two line segments (p1,p2) and (q1,q2) intersect.
	 * if true is returned, the cross point range can be obtained by GemEuclid.getCrossPointRange().
	 * notice that the intersection can be line segment if they are on the same line.
	 * Then if intersect, the cross point range can be get by [xp1,xp2], arranged along vector (q1,q2).
	 */
	static isLineSegLineSegIntersect(p1:GemPoint, p2:GemPoint, q1:GemPoint, q2:GemPoint):boolean{
		let rval = false;
		if (GemEuclid.isPointLineSegIntersect(q1,p1,p2)){
			GemEuclid.xp1.copy(q1);
			if (GemEuclid.isPointLineSegIntersect(q2,p1,p2)){
				GemEuclid.xp2.copy(q2);
			}else if (GemEuclid.isPointLineSegIntersect(p1,q1,q2)){
				if (GemEuclid.isPointLineSegIntersect(p2,q1,q2)){
					if (GemEuclid.dist(q2,p1)<GemEuclid.dist(q2,p2)) GemEuclid.xp2.copy(p1);
					else GemEuclid.xp2.copy(p2);
				}else{
					GemEuclid.xp2.copy(p1);
				}
			}else if (GemEuclid.isPointLineSegIntersect(p2,q1,q2)){
				GemEuclid.xp2.copy(p2);
			}else{
				GemEuclid.xp2.copy(q1);
			}
			return true;
		}else if (GemEuclid.isPointLineSegIntersect(q2,p1,p2)){
			GemEuclid.xp2.copy(q2);
			if (GemEuclid.isPointLineSegIntersect(p1,q1,q2)){
				if (GemEuclid.isPointLineSegIntersect(p2,q1,q2)){
					if (GemEuclid.dist(q2,p1)<GemEuclid.dist(q2,p2)) GemEuclid.xp1.copy(p2);
					else GemEuclid.xp1.copy(p1);
				}else{
					GemEuclid.xp1.copy(p1);
				}
			}else if (GemEuclid.isPointLineSegIntersect(p2,q1,q2)){
				GemEuclid.xp1.copy(p2);
			}else{
				GemEuclid.xp1.copy(q2);
			}
			return true;
		}else if (GemEuclid.isPointLineSegIntersect(p1,q1,q2)){
			if (GemEuclid.isPointLineSegIntersect(p2,q1,q2)){
				if (GemEuclid.dist(q2,p1)<GemEuclid.dist(q2,p2)){
					GemEuclid.xp1.copy(p1);
					GemEuclid.xp2.copy(p2);
				}else{
					GemEuclid.xp1.copy(p2);
					GemEuclid.xp2.copy(p1);
				}
			}else{
				GemEuclid.xp1.copy(p1);
				GemEuclid.xp2.copy(p1);
			}
			return true;
		}else if (GemEuclid.isPointLineSegIntersect(p2,q1,q2)){
			GemEuclid.xp1.copy(p2);
			GemEuclid.xp2.copy(p2);
			return true;
		}else if (this.isLineSegLineSegIntersectAtMidpoint(p1,p2,q1,q2)){
			GemEuclid.xp1.copy(GemEuclid.xpnt);
			GemEuclid.xp2.copy(GemEuclid.xpnt);
			return true;
		}else{
			return false;
		}
	}
	static getCrossPointRange():GemPoint[]{
		return [GemEuclid.xp1,GemEuclid.xp2];
	}
	static getCrossPoint():GemPoint{
		return this.xpnt;
	}
	static isLineSegLineSegIntersectAtMidpoint(p1:GemPoint, p2:GemPoint, q1:GemPoint, q2:GemPoint):boolean{
		let rval = false;
	    let rc = 0;
		if (((rc = GemEuclid.ccw(p1,q1,p2)) != 0) &&
		GemEuclid.ccw(q1,p2,q2) == rc && 
		GemEuclid.ccw(p2,q2,p1) == rc &&
		GemEuclid.ccw(q2,p1,q1) == rc) rval = true;
	    if (rval) rval = GemEuclid.isOpenLineOpenLineIntersect(p1,p2,q1,q2);
		return rval;
	}
	static isPointLineSegIntersect(p1:GemPoint, q1:GemPoint, q2:GemPoint):boolean{
		let rval = false;
		if (p1.equals(q1) || p1.equals(q2)){
			rval = true;
		}else if (!q1.equals(q2)){
			if(GemEuclid.ccw(q1,p1,q2) == 0){
				if ((q1.x < p1.x && p1.x < q2.x) ||
					(q1.x > p1.x && p1.x > q2.x) ||
					(q1.y < p1.y && p1.y < q2.y) ||
					(q1.y > p1.y && p1.y > q2.y)) rval = true;
			}
		}
	    if (rval) GemEuclid.xpnt.copy(p1);
	    return rval;
	}
	static isOpenLineOpenLineIntersect(p1:GemPoint, p2:GemPoint, q1:GemPoint, q2:GemPoint):boolean{
	    /**
	    *	line equation:
	    *		line throu 2 points (x1,y1) and (x2,y2) is,
	    *		(y - y1)(x2 - x1) = (x - x1)(y2 - y1)
	    *		ax + by + c = 0
	    *		a = (y1 - y2)
	    *		b = (x2 - x1)
	    *		c = y1(x1-x2)+x1(y2-y1) = x1y2-x2y1
	    *	cross point:
	    *		line 1 := a1x + b1y + c1 = 0
	    *		line 2 := a2x + b2y + c2 = 0
	    *		cross point (cx,cy) :=
	    *		cx = (b1c2 - b2c1)/den
	    *		cy = (a2c1 - a1c2)/den
	    *		where den = a1b2 - a2b1
	    **/
	    let a1,b1,a2,b2;
	    let	c1,c2,den,xc,yc;
	    a1 = p1.y - p2.y;
	    b1 = p2.x - p1.x;
	    c1 = p1.x*p2.y - p2.x*p1.y;
	    a2 = q1.y - q2.y;
	    b2 = q2.x - q1.x;
	    c2 = q1.x*q2.y - q2.x*q1.y;
	    den = a1*b2 - a2*b1;
	    if (Math.abs(den) < 0.5) return false;
	    /* (memo) den must be integer. so it will be zero if two lines
	    are parallel. but I cannot use 'if (NINT(den) == 0)' because
	    den may be too big number */
	    xc = (b1*c2-b2*c1)/den;
	    yc = (a2*c1-a1*c2)/den;
	    GemEuclid.xpnt.setXY(xc,yc);
	    return true;
	}
	static isHalfOpenLineOpenLineIntersect(p1:GemPoint, p2:GemPoint, q1:GemPoint, q2:GemPoint):boolean{
		const savex = GemEuclid.xpnt.x;
		const savey = GemEuclid.xpnt.y;
		let rval = GemEuclid.isOpenLineOpenLineIntersect(p1,p2,q1,q2);
		if (rval){
				if (p1.x <= p2.x){
						if (GemEuclid.xpnt.x < p1.x) rval = false;
				}else if (p1.x < GemEuclid.xpnt.x) rval = false;
				if (p1.y <= p2.y){
						if (GemEuclid.xpnt.y < p1.y) rval = false;
				}else if (p1.y < GemEuclid.xpnt.y) rval = false;
		}
		if (!rval) GemEuclid.xpnt.setXY(savex,savey);
		return rval;
	}
	static isLineSegHalfOpenLineIntersect(p1:GemPoint, p2:GemPoint, q1:GemPoint, q2:GemPoint, atmidpoint:boolean=false):boolean{
		const rc1 = GemEuclid.ccw(q1,p1,p2);
		const rc2 = GemEuclid.ccw(q1,p1,q2);
		const rc3 = GemEuclid.ccw(q1,q2,p2);
		let rval = false;
		if (rc1 == 0){
			if (q1.equals(p1) && !atmidpoint) rval = true;
			else if (q1.equals(p2)) rval = true;
			else rval = false;
		}else if (rc2 == rc1 && rc3 == rc1){
				rval = GemEuclid.isOpenLineOpenLineIntersect(p1,p2,q1,q2);
		}else if (rc2 == rc1 && rc3 == 0){
			if (atmidpoint) rval = false;
			else{
				GemEuclid.xpnt.copy(p2);
				rval = true;
			}
		}else if (rc2 == 0 && rc3 == rc1){
			if (atmidpoint) rval = false;
			else{
					GemEuclid.xpnt.copy(p1);
					rval = true;
			}
		}
		return rval;
	}
	static distBboxBbox(bb1:GemBbox, bb2:GemBbox):number{
		if (bb1.ymin<=bb2.ymax||bb2.ymin<=bb1.ymax){
			if (bb1.xmax<bb2.xmin) return bb2.xmin-bb1.xmax;
			else if (bb2.xmax<bb1.xmin) return bb1.xmin-bb2.xmax;
			else return 0;
		}else if (bb1.xmin<=bb2.xmax||bb2.xmin<=bb1.xmax){
			if (bb1.ymax<bb2.ymin) return bb2.ymin-bb1.ymax;
			else if (bb2.ymax<bb1.ymin) return bb1.ymin-bb2.ymax;
			else return 0;
		}else{
			if (bb1.ymax<bb2.ymin){
				GemEuclid.xp1.y = bb1.ymax;
				GemEuclid.xp2.y = bb2.ymin;
			}else{
				GemEuclid.xp1.y = bb1.ymin;
				GemEuclid.xp2.y = bb2.ymax;
			}
			if (bb1.xmax<bb2.xmin){
				GemEuclid.xp1.x = bb1.xmax;
				GemEuclid.xp2.x = bb2.xmin;
			}else{
				GemEuclid.xp1.x = bb1.xmin;
				GemEuclid.xp2.x = bb2.xmax;
			}
			return GemEuclid.dist(GemEuclid.xp1,GemEuclid.xp2);
		}
	}
}
/**
 * bounding box of a 2d spatial object.
 * this is a derivative info, then not serializable.
 */
export class GemBbox implements GemBboxI{
	xmin:number = Number.MAX_SAFE_INTEGER;
	ymin:number = Number.MAX_SAFE_INTEGER;
	xmax:number = Number.MIN_SAFE_INTEGER;
	ymax:number = Number.MIN_SAFE_INTEGER;
	// cache
	private cen:GemPoint = null;
	private _cnrs:GemPoint[] = null; // LL, LR, UR, UL points.
	private _poly:GemPoly = null;
	// private fshapes:Flatten.Polygon[] = [];
	// private fsegs:Flatten.Segment[] = [];
	// flattenPolygonsI():Flatten.Polygon[]{
	// 	if (this.fshapes.length<=0){
	// 		const fpgon = new Flatten.Polygon;
	// 		const fbox = new Flatten.Box(this.xmin,this.ymin,this.xmax,this.ymax);
	// 		fpgon.addFace(fbox);
	// 		this.fshapes.push(fpgon);
	// 		/*
	// 		why bbox is modeled as polygon in Flatten world, whereas our polygon is
	// 		modeled as set of segments?
	// 		we want pattern polygons can only be selected at their boundaries.
	// 		so we model the shape of polygons by this outlines.
	// 		On the other hand, we want to select all if the user drag large
	// 		area by mouse. Then, we model the probe rectangle as polygon.
	// 		*/
	// 		/**
	// 		 * why in gemshape module?
	// 		 * Flatten.Polygon.intersect tests the parameter object by 'instanceof'
	// 		 * directive, which works only for the objects created in the same module.
	// 		 * then, we need to collect all the Flatten-js related code in one module.
	// 		 */
	// 	}
	// 	return this.fshapes;
	// }
	// flattenSegmentsI():Flatten.Segment[]{
	// 	if (this.fsegs.length<=0){
	// 		this.fsegs.push(new Flatten.Segment(new Flatten.Point(this.xmin,this.ymin),new Flatten.Point(this.xmax,this.ymin)));
	// 		this.fsegs.push(new Flatten.Segment(new Flatten.Point(this.xmax,this.ymin),new Flatten.Point(this.xmax,this.ymax)));
	// 		this.fsegs.push(new Flatten.Segment(new Flatten.Point(this.xmax,this.ymax),new Flatten.Point(this.xmin,this.ymax)));
	// 		this.fsegs.push(new Flatten.Segment(new Flatten.Point(this.xmin,this.ymax),new Flatten.Point(this.xmin,this.ymin)));
	// 	}
	// 	return this.fsegs;
	// }
	/**
	 * check if this bbox intersects with the given shape.
	 * @param shape
	 * @returns true if this bbox intersects with the shape. if true, the crossing points can be obtained by this.xpnts.
	 */
	intersect(shape:GemShape,thisfilled:boolean=true,thatfilled:boolean=true):boolean{
		return this.toPoly().intersect(shape,thisfilled,thatfilled);
			// if true, the crosspoints are left in this._poly.xpnts.
	}
	/**
	 * MAX/MIN_SAFE_INTEGER で初期化する。
	 */
	initialize():GemBbox{
		this.xmin = Number.MAX_SAFE_INTEGER;
		this.ymin = Number.MAX_SAFE_INTEGER;
		this.xmax = Number.MIN_SAFE_INTEGER;
		this.ymax = Number.MIN_SAFE_INTEGER;
		return this;
	}
	get xpnts():GemPoint[]{
		return this._poly.xpnts;
	}
	contains(p:GemPoint):boolean{
		return this.xmin<=p.x&&p.x<=this.xmax&&
			this.ymin<=p.y&&p.y<=this.ymax;
	}
	width():number{
		return this.xmax - this.xmin;
	}
	height():number{
		return this.ymax - this.ymin;
	}
	
	toString():string{
		return '(('+this.xmin+', '+this.ymin+'), ('+this.xmax+', '+this.ymax+'))';
	}
	clearCache():GemBbox{
		this.cen = null;
		this._cnrs = null;
		this._poly = null;
		return this;
	}
	defined():boolean{
		return this.xmin<=this.xmax;
	}
	addPoint(p:GemPoint):GemBbox{
		return this.addXY(p.x,p.y);
	}
	addXY(x:number, y:number):GemBbox{
		this.xmin = Math.min(this.xmin,x);
		this.ymin = Math.min(this.ymin,y);
		this.xmax = Math.max(this.xmax,x);
		this.ymax = Math.max(this.ymax,y);
		this.clearCache();
		return this;
	}
	add(that:GemBbox):GemBbox{
		if (that){
			this.xmin = Math.min(this.xmin,that.xmin);
			this.ymin = Math.min(this.ymin,that.ymin);
			this.xmax = Math.max(this.xmax,that.xmax);
			this.ymax = Math.max(this.ymax,that.ymax);
			this.clearCache();
		}
		return this;
	}
	center():GemPoint{
		if (!this.cen){ // null is returned if undefined.
			this.cen = new GemPoint((this.xmin+this.xmax)/2,(this.ymin+this.ymax)/2);
		}
		return this.cen; 
	}
	copy(that:GemBbox):GemBbox{
		this.xmin = that.xmin;
		this.ymin = that.ymin;
		this.xmax = that.xmax;
		this.ymax = that.ymax;
		return this;
	}
	get cnrs():GemPoint[]{
		if (!this._cnrs){
			this._cnrs = [
				new GemPoint(this.xmin,this.ymin),
				new GemPoint(this.xmax,this.ymin),
				new GemPoint(this.xmax,this.ymax),
				new GemPoint(this.xmin,this.ymax)
			]
		}
		return this._cnrs;
	}
	/**
	 * @returns 4 poly-points in ccw, starting from lower left corner.
	 */
	toPoly():GemPoly{
		if (!this._poly){
			this._poly = new GemPoly(0,null);
			this._poly.isclosed = true;
			this._poly.pps.push(
				new GemMoveTo(0,this._poly,this.cnrs[0].mkcopy()),
				new GemMoveTo(0,this._poly,this.cnrs[1].mkcopy()),
				new GemMoveTo(0,this._poly,this.cnrs[2].mkcopy()),
				new GemMoveTo(0,this._poly,this.cnrs[3].mkcopy())
			);
		}
		return this._poly;
	}
	toI():GemBboxI{
		return {
			xmin:this.xmin,
			ymin:this.ymin,
			xmax:this.xmax,
			ymax:this.ymax
		}
	}
	fromI(that:GemBboxI):GemBbox{
		this.xmin = that.xmin;
		this.ymin = that.ymin;
		this.xmax = that.xmax;
		this.ymax = that.ymax;
		return this;
	}
}

export abstract class GemShape implements GemShapeI{
	lineno:number = 0;
	par:any = null; // some parent object
	id:string = ""; // shape id.
	fig:string = ""; // keyword that describes the shape
	isvoid:boolean = false; // is hole shape. in G-format, hole shapes and outline shapes are independent.
	layer:number = 0; // 1,2,.. is set when instantiated.
	// non-if items
	net:Object = null;
	selected:boolean = false;
	bb:GemBbox = null;
	fpgons:(Flatten.Polygon|Flatten.Segment|Flatten.Point)[] = [];
	fsegs:(Flatten.Segment|Flatten.Arc|Flatten.Point)[] = [];
	xpnts:GemPoint[] = [];
	abstract center():GemPoint;
	abstract setparR(par:any):void;
	abstract textpnt():GemPoint;
	select(state:boolean):void{
		this.selected = state;
	}
	abstract classname:string;
	constructor(lineno:number, par:any){
		this.lineno = lineno;
		this.par = par;
	}
	clearCache(){
		this.fpgons = [];
		this.fsegs = [];
		this.bb = null;
		this.xpnts = [];
	}
	toI():GemShapeI{
		return {
			lineno: this.lineno,
			classname: this.classname,
			id: this.id,
			fig: this.fig,
			isvoid: this.isvoid,
			layer: this.layer
		}
	}
	fromI(par:any, that:GemShapeI):GemShape{
		this.lineno = that.lineno;
		this.par = par;
		this.id = that.id;
		this.fig = that.fig;
		this.isvoid = that.isvoid;
		this.layer = that.layer;
		return this;
	}
	copy(that:GemShape):GemShape{
		this.lineno = that.lineno;
		this.id = that.id;
		this.fig = that.fig;
		this.isvoid = that.isvoid;
		this.layer = that.layer;
		return this;
	}
	/**
	 * check if two shapes intersect eachother.
	 * @param that check if this shape intersects with the given shape.
	 * @param thisfilled inside of this shape belongs to the shape.
	 * @param thatfilled inside of that shape belongs to the shape.
	 * @returns true if intersection. if true, cross points can be obtained by this.xpnts.
	 */
	intersect(that:GemShape|GemPoint, thisfilled:boolean=true, thatfilled:boolean=true):boolean{
		const fshapes1 = thisfilled? this.flattenPolygonsI(): this.flattenSegmentsI();
		const fshapes2 = that instanceof GemPoint ? [new Flatten.Point(that.x,that.y)]:
			thatfilled? that.flattenPolygonsI(): that.flattenSegmentsI();
		for(var i =0 ; i < fshapes1.length ; i++){
			const ishape = fshapes1[i];
			for(var j = 0 ; j < fshapes2.length ; j++){
				const jshape = fshapes2[j];
				if (ishape instanceof Flatten.Point){
					if (jshape instanceof Flatten.Point){
						if (ishape.equalTo(jshape)){
							this.xpnts = [new GemPoint(ishape.x,ishape.y)];
							return true;
						}
					}else{
						if (jshape.contains(ishape)){
							this.xpnts = [new GemPoint(ishape.x,ishape.y)];
							return true;
						}
					}
				}else{
					// const ip:Flatten.Point[] = ishape.intersect(jshape); // intersection points
					const ip:Flatten.Point[] = ishape.intersect(jshape); // intersection points
					if (ip && ip.length>0){
						this.xpnts = ip.map(p=>new GemPoint(p.x,p.y));
						return true;
					}else if (thisfilled && ishape.contains(jshape.vertices[0])){
						this.xpnts = this.xpnts = [new GemPoint(jshape.vertices[0].x,jshape.vertices[0].y)]
						return true;
					}else if (!(jshape instanceof Flatten.Point) && thatfilled && jshape.contains(ishape.vertices[0])){
						this.xpnts = this.xpnts = [new GemPoint(jshape.vertices[0].x,jshape.vertices[0].y)]
						return true;
					}
				}
			}
		}
		return false;
	}
	abstract bbox():GemBbox;
	/**
	 * returns the equivalent set of Flatten shape objects, to help 2d geometry calculation.
	 * flattenPolygons() returns the polygon with inside, and flattenSegments() returns the
	 * outside only. when a polygon is actually a line segment or a point, flattenPolygons() returns
	 * the line segment or a point. flattenSegments() does not return a object when it actually a point.
	 */
	abstract flattenPolygonsI():(Flatten.Polygon|Flatten.Segment|Flatten.Point)[];
	abstract flattenSegmentsI():(Flatten.Segment|Flatten.Arc|Flatten.Point)[];

	/**
	 * check if the probe rectangle intersects this shape, assuming this shape is shown on canvas.
	 */
	abstract isclosed:boolean; // whether closed figure or open figure.
	abstract width():number; // the stroke width (always 0 in closed figure).
	/**
	 * issue {G.ctx.GMoveTo(),G.ctx.lineTo()/G.ctx.arc(),....,G.ctx.closePath()}, in ccw/cw order,
	 * depending on ishole is on/off.
	 */
	abstract mkcopy():GemShape;
	abstract rotate(degree:number, rotcen:GemPoint):GemShape;
	abstract move(dx:number, dy:number):GemShape;
	abstract flip(x0:number):GemShape;
	static mkFromI(par:any, refshape:GemShapeI):GemShape{
		let shape = null;
		switch(refshape.classname){
		case 'GemPoly':
			const poly = shape = new GemPoly(refshape.lineno,par);
			poly.fromI(par,<GemPolyI>refshape);
			break;
		case 'GemCircle':
			const circ = shape = new GemCircle(0,par);
			circ.fromI(par,<GemCircleI>refshape);
			break;
		default:
			throw new Error("refshape.classname="+refshape.classname);
		}
		return shape;
	}
	static sameshape(shape1:GemShape, shape2:GemShape):boolean{
		if (shape1.classname!==shape2.classname) return false;
		else if (shape1.classname==='GemCircle'){
			const circ1:GemCircle = <GemCircle>shape1;
			const circ2:GemCircle = <GemCircle>shape2;
			return (circ1.dia === circ2.dia); // center is not a library info.
		}else{
			const poly1:GemPoly = <GemPoly>shape1;
			const poly2:GemPoly = <GemPoly>shape2;
			if (poly1.isclosed!=poly2.isclosed) return false;
			else if (poly1.isvoid!=poly2.isvoid) return false;
			else if (poly1.ape!=poly2.ape) return false;
			else if (poly1.pps.length!=poly2.pps.length) return false;
			for(let i = 0 ; i < poly1.pps.length ; i++){
				const pp1:GemMoveTo = poly1.pps[i];
				const pp2:GemMoveTo = poly2.pps[i];
				if (pp1.classname!==pp2.classname) return false;
				else if (!pp1.p.equals(pp2.p)) return false;
				if (pp1.classname==='GemArcTo'){
					const a1:GemArcTo = <GemArcTo> pp1;
					const a2:GemArcTo = <GemArcTo> pp2;
					if (a1.isccw != a2.isccw) return false;
					else if (!a1.ac.equals(a2.ac)) return false;
				}else if (pp1.classname==='GemArc3To'){
					const a1:GemArc3To = <GemArc3To> pp1;
					const a2:GemArc3To = <GemArc3To> pp2;
					if (!a1.mp.equals(a2.mp)) return false;
				}
			}
			return true;
		}
	}
}
export class GemMoveTo implements GemMoveToI{
	lineno:number = 0;
	par:GemPoly = null;
	p:GemPoint = null; // to-point
	// cache
	bb:GemBbox = null;
	get classname(){return 'GemMoveTo';}
	static fromJSON(par:GemPoly, json:string):GemMoveTo{
		return new GemMoveTo(0,par,null).fromI(par,<GemMoveToI>JSON.parse(json))
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}

	fromI(par:GemPoly, ref:GemMoveToI):GemMoveTo{
		this.p = new GemPoint(0,0).fromI(ref.p);
		this.lineno = ref.lineno;
		return this;
	}
	toI():GemMoveToI{
		return {
			lineno: this.lineno,
			classname: this.classname,
			p: this.p.toI()
		}
	}
	/** 第3パラメタはコピーせずに内部で利用される */
	constructor(lineno:number, par:GemPoly, p:GemPoint){
		this.lineno = lineno;
		this.par = par;
		this.p = p;
	}
	toString():string{
		return "("+this.classname+" "+this.p.toString()+")";
	}
	healthcheck(prev:GemPoint):string{
		if (prev===this.p) return "duplicated point";
		return null;
	}
	clearCache():GemMoveTo{
		this.bb = null;
		return this;
	}
	bbox():GemBbox{
		throw new Error("use bboxArc(prev), instead of bbox().");
	}
	bboxArc(prev:GemPoint):GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			this.bb.addPoint(this.p);
			this.bb.addPoint(prev);
		}
		return this.bb;
	}
	copy(that:GemMoveTo):GemMoveTo{
		this.p.copy(that.p);
		this.clearCache();
		return this;
	}
	mkcopy():GemMoveTo{
		var that = new GemMoveTo(this.lineno,this.par,this.p.mkcopy());
		return that.copy(this);
	}
	rotate(degree:number, rotcen:GemPoint):GemMoveTo{
		this.p.rotate(degree,rotcen);
		this.clearCache();
		return this;
	}
	move(dx:number, dy:number):GemMoveTo{
		this.p.move(dx,dy);
		this.clearCache();
		return this;
	}
	flip(x0:number):GemMoveTo{
		this.p.flip(x0);
		this.clearCache();
		return this;
	}
	static mkFromI(par:GemPoly, refmoveto:GemMoveToI):GemMoveTo{
		let moveto:GemMoveTo = null;
		switch(refmoveto.classname){
		case 'GemMoveTo':
			moveto = new GemMoveTo(0,par,null).fromI(par, refmoveto);
			break;
		case 'GemArcTo':
			const arcto:GemArcTo = moveto =
				new GemArcTo(0,par,null,null,false)
				.fromI(par,<GemArcToI>refmoveto);
			break;
		case 'GemArc3To':
			const arc3to:GemArc3To = moveto = 
				new GemArc3To(0,par,null,null)
				.fromI(par,<GemArc3ToI>refmoveto);
			break;
		default:
			throw new Error("refmoveto.classname="+refmoveto.classname);
		}
		return moveto;
	}
}
export class GemArcTo extends GemMoveTo implements GemArcToI{
	ac:GemPoint; // arc center
	isccw:boolean; // rarc is ccw

	// work
	private static GemBboxwk:GemPoint;
	// cache
	private mpwk:GemPoint = null;
	get classname(){return 'GemArcTo';}
	static fromJSON(par:GemPoly, json:string):GemArcTo{
		return new GemArcTo(0,par,null,null,false).fromI(par,<GemArcToI>JSON.parse(json))
	}

	toJSON():string{
		return JSON.stringify(this.toI());
	}

	fromI(par:GemPoly, ref:GemArcToI):GemArcTo{
		super.fromI(par,ref);
		this.ac = new GemPoint(0,0).fromI(ref.ac);
		this.isccw = ref.isccw;
		return this;
	}
	toI():GemArcToI{
		return {
			...super.toI(),
			classname: this.classname,
			ac: this.ac.toI(),
			isccw: this.isccw
		}
	}
	constructor(lineno:number, par:GemPoly, p:GemPoint, ac:GemPoint, isccw:boolean){
		super(lineno,par,p);
		this.ac = ac;
		this.isccw = isccw;
	}
	clearCache():GemArcTo{
		super.clearCache();
		this.mpwk = null;
		return this;
	}
	tostring():string{
		return "(GArcTo "+this.p.toString()+" ac="+this.ac.toString()+" isccw="+this.isccw+")";
	}
	healthcheck(prev:GemPoint):string{
		if (prev===this.ac) return "arc center coincides with previous point";
		if (this.ac===this.p) return "arc center coincides with the point";
		const r1 = GemEuclid.dist(this.ac,prev);
		const r2 = GemEuclid.dist(this.ac,this.p);
		const e = Math.abs(r1-r2)/Math.min(r1,r2);
		if (e>0.1) return "arc center is at wrong coord (>10% error).";
		return null;
	}
	// remove redundant last point
	mp(prev:GemPoint):GemPoint{
		if (!this.mpwk){
			var deg1 = GemEuclid.angdeg(this.ac,this.isccw?prev:this.p);
			var deg2 = GemEuclid.angdeg(this.ac,this.isccw?this.p:prev);
			var radius = GemEuclid.dist(this.ac,prev);
			if (deg2<deg1) deg2 += 360;
			this.mpwk = new GemPoint(0,0).copy(this.ac).movePolar(GemEuclid.degreeModulo((deg1+deg2)/2),radius);
		}
		return this.mpwk;
	}
	bbox():GemBbox{
		throw new Error("(bug) use bboxArc(prev) instead of bbox().");
	}
	bboxArc(prev:GemPoint):GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			if (prev.equals(this.p)){ // circle is meant
				const radius = GemEuclid.dist(this.p,this.ac);
				this.bb.addXY(this.ac.x+radius,this.ac.y+radius);
				this.bb.addXY(this.ac.x-radius,this.ac.y-radius);
			}else{
				this.bb.addPoint(prev);
				this.bb.addPoint(this.p);
				// add points on arc at 90 degrees.
				var deg1 = GemEuclid.angdeg(this.ac,this.isccw?prev:this.p);
				var deg2 = GemEuclid.angdeg(this.ac,this.isccw?this.p:prev);
				var radius = GemEuclid.dist(this.ac,prev);
				if (deg2<deg1) deg2 += 360;
				if (!GemArcTo.GemBboxwk) GemArcTo.GemBboxwk = new GemPoint(0,0);
				for(var deg = Math.ceil(deg1/90)*90 ; deg < deg2 ; deg += 90){
					this.bb.addPoint(GemArcTo.GemBboxwk.copy(this.ac).movePolar(GemEuclid.degreeModulo(deg),radius));
				}
			}
		}
		return this.bb;
	}
	copy(that:GemArcTo):GemArcTo{
		super.copy(that);
		this.ac.copy(that.ac);
		this.isccw = that.isccw;
		return this;
	}
	mkcopy():GemArcTo{
		var that = new GemArcTo(0,this.par,this.p.mkcopy(),this.ac.mkcopy(),this.isccw);
		return that.copy(this);
	}
	rotate(degree:number, rotcen:GemPoint):GemArcTo{
		super.rotate(degree,rotcen);
		this.ac.rotate(degree,rotcen);
		return this;
	}
	move(dx:number, dy:number):GemArcTo{
		super.move(dx,dy);
		this.ac.move(dx,dy);
		return this;
	}
	flip(x0:number):GemArcTo{
		super.flip(x0);
		this.ac.flip(x0);
		this.isccw = !this.isccw;
		return this;
	}
}
export class GemArc3To extends GemMoveTo implements GemArc3ToI{
	mp:GemPoint; // middle-point
	// work
	private static GemBboxwk = new GemPoint(0,0);
	// cache
	private acwk:GemPoint = null;
	get classname(){return 'GemArc3To';}
	static fromJSON(par:GemPoly, json:string):GemArc3To{
		return new GemArc3To(0,par,null,null).fromI(par,<GemArc3ToI>JSON.parse(json))
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:GemPoly, ref:GemArc3ToI):GemArc3To{
		super.fromI(par,ref);
		this.mp = new GemPoint(0,0).fromI(ref.mp);
		return this;
	}
	toI():GemArc3ToI{
		return {
			...super.toI(),
			classname: 'GemArc3To', // right most overides
			mp: this.mp.toI()
		}
	}
	constructor(lineno:number, par:GemPoly, p:GemPoint, mp:GemPoint){
		super(lineno,par,p);
		this.mp = mp;
	}
	clearCache():GemArc3To{
		super.clearCache();
		this.acwk = null;
		return this;
	}
	toString():string{
		return "("+this.p+" midpnt="+this.mp+")";
	}
	healthcheck(prev:GemPoint):string{
		if (prev.equals(this.p)||prev.equals(this.mp)||this.mp.equals(this.p)) return "duplicated points.";
		else return null;
	}
	ac(prev:GemPoint):GemPoint{
		if (!this.acwk){
			const x1 = prev.x;
			const y1 = prev.y;
			const x2 = this.mp.x;
			const y2 = this.mp.y;
			const x3 = this.p.x;
			const y3 = this.p.y;
			const x12 = x1 - x2; 
			const x13 = x1 - x3; 
	
			const y12 = y1 - y2; 
			const y13 = y1 - y3; 
	
			const y31 = y3 - y1; 
			const y21 = y2 - y1; 
	
			const x31 = x3 - x1; 
			const x21 = x2 - x1; 
	
			const sx13 = x1*x1-x3*x3;
			const sy13 = y1*y1-y3*y3;
			const sx21 = x2*x2-x1*x1;
			const sy21 = y2*y2-y1*y1;
	
			const f = (sx13 * x12 + sy13 * x12 + sx21 * x13 + sy21 * x13) / (2 * (y31 * x12 - y21 * x13)); 
			const g = (sx13 * y12 + sy13 * y12 + sx21 * y13 + sy21 * y13) / (2 * (x31 * y12 - x21 * y13)); 
			const c = -x1*x1 - y1*y1 - 2 * g * x1 - 2 * f * y1; 
			const h = -g; 
			const k = -f; 
			this.acwk = new GemPoint(h,k);
		}
		return this.acwk;
	}
	bbox():GemBbox{
		throw new Error("(bug) use bboxArc(prev) instead of bbox().");
	}
	bboxArc(prev:GemPoint):GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			this.bb.addPoint(prev);
			this.bb.addPoint(this.p);
			const ac = this.ac(prev);
			const isccw = GemEuclid.ccw(prev,this.mp,this.p)>0;
			// add points on arc at 90 degrees.
			var deg1 = GemEuclid.angdeg(ac,isccw?prev:this.p);
			var deg2 = GemEuclid.angdeg(ac,isccw?this.p:prev);
			var radius = GemEuclid.dist(ac,prev);
			if (deg2<deg1) deg2 += 360;
			for(var deg = Math.ceil(deg1/90)*90 ; deg < deg2 ; deg += 90){
				this.bb.addPoint(GemArc3To.GemBboxwk.copy(this.ac(prev)).movePolar(GemEuclid.degreeModulo(deg),radius));
			}
		}
		return this.bb;
	}
	copy(that:GemArc3To):GemArc3To{
		super.copy(that);
		this.mp.copy(that.mp);
		return this;
	}
	mkcopy():GemArc3To{
		return new GemArc3To(0,this.par,this.p.mkcopy(),this.mp.mkcopy()).copy(this);
	}
	rotate(degree:number, rotcen:GemPoint):GemArc3To{
		super.rotate(degree,rotcen);
		this.mp.rotate(degree,rotcen);
		return this;
	}
	move(dx:number, dy:number):GemArc3To{
		super.move(dx,dy);
		this.mp.move(dx,dy);
		return this;
	}
	flip(x0:number):GemArc3To{
		super.flip(x0);
		this.mp.flip(x0);
		return this;
	}
}
export class GemPoly extends GemShape implements GemPolyI{// GPolygon or GPolyline
	isclosed:boolean = false; // implicit line segment from the last point to the first point
	pps:GemMoveTo[] = [];
	ape:number = 0; // must be 0 if closed.
	//
	private pps_order_adjusted = false;
	private txtp:GemPoint = null;
	center():GemPoint{
		return this.bbox().center();
	}
	setparR(par:any){
		this.par = par;
		this.pps.forEach((m)=>{m.par = this;});
	}
	toString():string{
		let s = "(poly lineno="+this.lineno+" isclosed="+this.isclosed+" size="+this.pps.length;
		for(let pp of this.pps) s += " "+pp.p.toString();
		s += ")";
		return s;
	}
	textpnt():GemPoint{
		if (!this.txtp){
			this.txtp = new GemPoint(0,0).copy(this.pps[0].p);
			for(var i = 1 ; i < this.pps.length ; i++){
				if (this.txtp.y-this.txtp.x < this.pps[i].p.y-this.pps[i].p.x){
					this.txtp.copy(this.pps[i].p);
				}
			}
		}
		return this.txtp;
	}
	get classname(){return 'GemPoly';}
	constructor(lineno:number, par:any){
		super(lineno,par);
	}
	static fromJSON(par:any, json:string):GemPoly{
		return new GemPoly(0,par).fromI(par,<GemPolyI>JSON.parse(json))
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:any, ref:GemPolyI):GemPoly{
		super.fromI(par,ref);
		this.isclosed = ref.isclosed;
		this.ape = ref.ape;
		this.pps_order_adjusted = false;
		for(let i = 0 ; i < ref.pps.length ; i++){
			const m = ref.pps[i];
			if (m.classname==='GemArc3To'){
				const a = m as GemArc3ToI;
				this.pps.push(new GemArc3To(0,this,null,null).fromI(this,m as GemArc3ToI));
			}else if (m.classname==='GemArcTo'){
				this.pps.push(new GemArcTo(0,this,null,null,false).fromI(this,m as GemArcTo));
			}else{
				this.pps.push(new GemMoveTo(0,this,null).fromI(this,m as GemMoveTo));
			}
		}
		return this;
	}
	toI():GemPolyI{
		return {
			...super.toI(),
			classname: this.classname,
			isclosed: this.isclosed,
			pps: this.pps.map((m)=>{return m.toI();}),
			ape: this.ape
		}
	}
	/** endpnt(0) is the starting point, endpnt(non-0) is the end point. */
	endpnt(idx:number):GemPoint{
		if (idx==0) return this.pps[0].p;
		else return this.pps[this.pps.length-1].p;
	}
	width():number{return this.ape;}
	flattenPolygonsI():(Flatten.Polygon|Flatten.Segment|Flatten.Point)[]{
		if (this.fpgons.length<=0 && this.pps.length>0){
			const p0 = this.pps[0].p;
			if (this.pps.length===1){
				this.fpgons.push(new Flatten.Point(p0.x,p0.y));
			}else if (this.pps.length===2 && this.pps[0].classname === 'GemMoveTo' &&
				this.pps[1].classname === 'GemMoveTo'){
				const p1 = this.pps[1].p;
				this.fpgons.push(new Flatten.Segment(new Flatten.Point(p0.x,p0.y),new Flatten.Point(p1.x,p1.y)));
			}else if (this.isclosed || this.pps[0].p.equals(this.pps[this.pps.length-1].p)){
				const pgon = new Flatten.Polygon();
				const edges = this.flattenSegmentsI() as (Flatten.Segment|Flatten.Arc)[];
					// point is produced only when this.pps.length === 1
				pgon.addFace(edges);
				this.fpgons.push(pgon);
			}
		}
		return this.fpgons;
	}
	flattenSegmentsI():(Flatten.Segment|Flatten.Arc|Flatten.Point)[]{
		if (this.fsegs.length<=0 && this.pps.length>0){
			if (this.pps.length<=1){
				const p1 = this.pps[0].p;
				this.fsegs.push(new Flatten.Point(p1.x,p1.y));
			}else{
				for(var i = 0 ; i < this.pps.length ; i++){
					if (!this.isclosed && i===this.pps.length-1) break;
					const p1 = this.pps[i].p;
					const p2 = this.pps[(i+1)% this.pps.length];
					if (p2 instanceof GemArcTo){
						const GArcTo = <GemArcTo> p2;
						const f_ccw = !GArcTo.isccw;
						this.fsegs.push(new Flatten.Arc(
							new Flatten.Point(GArcTo.ac.x,GArcTo.ac.y),
							GemEuclid.dist(p1,GArcTo.ac),
							GemEuclid.angdeg(GArcTo.ac,p1)/180*Math.PI,
							GemEuclid.angdeg(GArcTo.ac,p2.p)/180*Math.PI,
							f_ccw
							));
					}else if (p2 instanceof GemArc3To){
						const GArcTo = <GemArc3To> p2;
						const f_ccw = !(GemEuclid.ccw(p1,p2.mp,p2.p)>0);
						this.fsegs.push(new Flatten.Arc(
							new Flatten.Point(GArcTo.ac(p1).x,GArcTo.ac(p1).y),
							GemEuclid.dist(p1,GArcTo.ac(p1)),
							GemEuclid.angdeg(GArcTo.ac(p1),p1)/180*Math.PI,
							GemEuclid.angdeg(GArcTo.ac(p1),p2.p)/180*Math.PI,
							f_ccw
							)
						);
					}else{
						this.fsegs.push(new Flatten.Segment(
							new Flatten.Point(p1.x,p1.y),
							new Flatten.Point(p2.p.x,p2.p.y))
						);
					}
				}
			}
		}
		return this.fsegs;
	}
	clearCache():GemPoly{
		super.clearCache();
		this.pps_order_adjusted = false;
		for(var i = 0 ; i < this.pps.length ; i++){
			this.pps[i].clearCache();
		}
		return this;
	}
	contains(pnt:GemPoint):boolean{
		return this.intersect(pnt);
	}
	ensurecw():void{
		if (!this.pps_order_adjusted && this.isclosed && this.isvoid != this.isccw()){
			this.reverse();
			this.pps_order_adjusted = true;
		}
	}
	private isccw():boolean{
		if (!this.isclosed) return undefined;
		if (this.pps.length===1){
			if (this.pps[0] instanceof GemArcTo){
				return (<GemArcTo>this.pps[0]).isccw;
			}else return undefined;
		}{
			var angtot = 0.0;
			var ang0:number = undefined;
			var ang9:number = undefined;
			var prev = this.pps[this.pps.length-1].p;
		for(var i = 0 ; i < this.pps.length ; i++){
				const pp = this.pps[i];
				if (pp instanceof GemArcTo || pp instanceof GemArc3To){
					const mp = (pp instanceof GemArcTo)? (<GemArcTo>pp).mp(prev): (<GemArc3To>pp).mp;
					const a1 = GemEuclid.angdeg(prev,mp);
					const a2 = GemEuclid.angdeg(mp,pp.p);
					if (ang0===undefined) ang0 = ang9 = a1;
					angtot += GemEuclid.degreeDiff180(ang9,a1);
					angtot += GemEuclid.degreeDiff180(a1,a2);
					ang9 = a2;
				}else{
					const a = GemEuclid.angdeg(prev,pp.p);
					if (ang0===undefined) ang0 = ang9 = a;
					angtot += GemEuclid.degreeDiff180(ang9,a);
					ang9 = a;
				}
				prev = pp.p;
			}
			angtot += GemEuclid.degreeDiff180(ang9,ang0);
			return angtot>0;
		}
	}
	reverse():void{
		if (this.isclosed){
			/*
			let poly = (mp=x, p=A), (mp=u p=B), (mp=v p=C), (mp=w p=D).
			(step1) re-pair mp and p by shifing p forward (processed backward)
			[(x A), (u B), (v C), (w D)] -->
			[(x D), (u A), (v B), (w C)]
			*/
			for(var i = this.pps.length-2 ; i >=0 ; i--){
				const pp1 = this.pps[i];
				const pp2 = this.pps[i+1];
				var sv = pp2.p; pp2.p = pp1.p; pp1.p = sv;
				if (pp2.classname==='GemArcTo')
					(<GemArcTo>pp2).isccw = !(<GemArcTo>pp2).isccw;
			}
			/* (step2) reverse the segment.
			[(x D), (u A), (v B), (w C)] -->
			[(w C), (v B), (u A), (x D)]
			*/
			// reverse the array of segments.
			for(var i = 0 ; i < this.pps.length/2 ; i++){
				const pp = this.pps[i];
				this.pps[i] = this.pps[this.pps.length-1-i];
				this.pps[this.pps.length-1-i] = pp;
			}
		}else{
			/*
			let poly = (mp=N/A, p=A), (mp=u p=B), (mp=v p=C), (mp=w p=D).
			(step1) re-pair mp and p by shifing p.
			[(NA A), (u B), (v C), (w D)] -->
			[(NA D), (u A), (v B), (w C)]
			*/
			for(var i = this.pps.length-2 ; i >=0 ; i--){
				const pp1 = this.pps[i];
				const pp2 = this.pps[i+1];
				var sv = pp2.p; pp2.p = pp1.p; pp1.p = sv;
				if (pp2.classname==='GemArcTo')
					(<GemArcTo>pp2).isccw = !(<GemArcTo>pp2).isccw;
			}
			/*
			(step2) rotate the array to adjust for new end point.
			[(NA D), (u A), (v B), (w C)] -->
			[(u A), (v B), (w C), (NA D)]
			*/
			this.pps.push(this.pps.shift());
			/* (step2) reverse the segment.
			[(u A), (v B), (w C), (NA D)] -->
			[(NA D), (w C), (v B), (u A)]
			*/
			for(var i = 0 ; i < this.pps.length/2 ; i++){
				const pp = this.pps[i];
				this.pps[i] = this.pps[this.pps.length-1-i];
				this.pps[this.pps.length-1-i] = pp;
			}
		}
		this.clearCache();
	}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			var prev = this.isclosed? this.pps[this.pps.length-1].p: this.pps[0].p;
			for(var i=0 ; i<this.pps.length ; i++){
				this.bb.add(this.pps[i].bboxArc(prev));
				prev = this.pps[i].p;
			}
		}
		return this.bb;
	}
	copy(that:GemPoly):GemPoly{
		super.copy(that);
		this.isclosed = that.isclosed;
		this.pps = [];
		for(var i = 0 ; i < that.pps.length ; i++){
			this.pps.push(that.pps[i].mkcopy());
		}
		this.ape = that.ape;
		return this;
	}
	mkcopy():GemPoly{
		return new GemPoly(0,this.par).copy(this);
	}
	rotate(degree:number, rotcen:GemPoint):GemPoly{
		for(var i = 0 ; i < this.pps.length ; i++){
			var pp = this.pps[i];
			pp.rotate(degree,rotcen);
		}
		return this;
	}
	move(dx:number, dy:number):GemPoly{
		for(var i = 0 ; i < this.pps.length ; i++){
			var pp = this.pps[i];
			pp.move(dx,dy);
		}
		return this;
	}
	flip(x0:number):GemPoly{
		for(var i = 0 ; i < this.pps.length ; i++){
			var pp = this.pps[i];
			pp.flip(x0);
		}
		return this;
	}
}
export class GemText extends GemShape implements GemTextI{
	readonly pnt:GemPoint = new GemPoint(0,0);
	private cen:GemPoint = new GemPoint(0,0);
	text:string;
	charheight:number;
	charwidth:number;
	justification:string; // 'LEFT'/'RIGHT'/'CENTER'
	constructor(lineno:number, par:any, px:number, py:number, text:string, charheight:number=1000, charwidth:number=500, justification='LEFT'){
		super(lineno,par);
		this.pnt.setXY(px,py);
		this.text = text;
		this.charheight = charheight;
		this.charwidth = charwidth;
		this.justification = justification;
	}
	get textwidth():number{
		return this.text.length*this.charwidth;
	}
	get textheight():number{
		return this.charheight;
	}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			this.bb.addXY(this.cen.x-this.textwidth/2,this.cen.y-this.textheight/2);
			this.bb.addXY(this.cen.x+this.textwidth/2,this.cen.y+this.textheight/2);
		}
		return this.bb;
	}
	center():GemPoint{
		if (this.justification==='LEFT'){
			this.cen.setXY(this.pnt.x+this.textwidth/2,this.pnt.y);
		}else if (this.justification==='RIGHT'){
			this.cen.setXY(this.pnt.x-this.textwidth/2,this.pnt.y);
		}else if (this.justification==='CENTER'){
			this.cen.setXY(this.pnt.x,this.pnt.y);
		}
		return this.cen;
	}
	width():number{return 0};
	toString():string{
		return "(text pnt="+this.cen.toString()+" text=\""+this.text+"\")";
	}
	setparR(par:any){this.par = par;}
	get classname(){return 'GemText';}
	textpnt():GemPoint{
		return this.center();
	}
	flattenPolygonsI(): Flatten.Polygon[] {
		return null;
	}
	flattenSegmentsI(): (Flatten.Segment | Flatten.Arc)[] {
		return null;
	}
	get isclosed():boolean{return false;}
	mkcopy(): GemShape {
		return new GemText(this.lineno,this.par,this.pnt.x,this.pnt.y,this.text,this.charheight,this.charwidth,this.justification);
	}
	rotate(degree: number, rotcen: GemPoint): GemShape {
		this.pnt.rotate(degree,rotcen);
		return this;
	}
	move(dx: number, dy: number): GemShape {
		this.pnt.move(dx,dy);
		return this;
	}
	flip(x0: number): GemShape {
		this.pnt.flip(x0);
		return this;
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:any,ref:GemTextI):GemText{
		super.fromI(par,ref);
		this.pnt.copy(ref.pnt);
		this.text = ref.text;
		this.charheight = ref.charheight
		this.charwidth = ref.charwidth;
		this.justification = ref.justification;
		return this;
	}
	toI():GemTextI{
		return {
			...super.toI(),
			classname: this.classname,
			pnt: this.pnt.toI(),
			text: this.text,
			charheight: this.charheight,
			charwidth: this.charwidth,
			justification: this.justification
		}
	}
}
export class GemCircle extends GemShape implements GemCircleI{
	readonly cen:GemPoint = new GemPoint(0,0); // center
	dia:number = 0; // 
	innerdia:number = 0; // donut shape.
	center():GemPoint{
		return this.cen;
	}
	toString():string{
		return "(GemCircle cen="+this.cen.toString()+" dia="+this.dia+")";
	}
	setparR(par:any){this.par = par;}
	get classname(){return 'GemCircle';}
	textpnt():GemPoint{
		return this.cen;
	}
	constructor(lineno:number, par:any, cx:number=0, cy:number=0, dia:number=0, innerdia:number=0){
		super(lineno,par);
		this.cen.x = cx;
		this.cen.y = cy;
		this.dia = dia;
		this.innerdia = innerdia;
	}
	static fromJSON(par:any,json:string):GemCircle{
		return new GemCircle(0,par).fromI(par,<GemCircleI>JSON.parse(json))
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:any,ref:GemCircleI):GemCircle{
		super.fromI(par,ref);
		this.cen.copy(ref.cen);
		this.dia = ref.dia;
		this.innerdia = ref.innerdia;
		return this;
	}
	toI():GemCircleI{
		return {
			...super.toI(),
			classname: this.classname,
			cen: this.cen.toI(),
			dia: this.dia,
			innerdia: this.innerdia
		}
	}
	flattenPolygonsI():(Flatten.Polygon|Flatten.Segment|Flatten.Point)[]{
		if (this.fpgons.length<=0){
			const fpgon = new Flatten.Polygon();
			fpgon.addFace(new Flatten.Circle(new Flatten.Point(this.cen.x,this.cen.y),this.dia/2));
			this.fpgons.push(fpgon);
		}
		return this.fpgons;
	}
	flattenSegmentsI():(Flatten.Segment|Flatten.Arc|Flatten.Point)[]{
		if (this.fsegs.length<=0){
			const f_ccw = !this.isvoid;
			this.fsegs.push(new Flatten.Arc(new Flatten.Point(this.cen.x,this.cen.y),
				this.dia/2,
				0,
				Math.PI*2,
				f_ccw)
			);
			if (this.innerdia>0){
				this.fsegs.push(new Flatten.Arc(new Flatten.Point(this.cen.x,this.cen.y),
					this.innerdia/2,
					0,
					Math.PI*2,
					f_ccw)
				);
			}
		}
		return this.fsegs;
	}
	clearCache():GemCircle{
		super.clearCache();
		return this;
	}
	get isclosed():boolean{return true;}
	width():number{return 0;}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			this.bb.addXY(this.cen.x-this.dia/2,this.cen.y-this.dia/2);
			this.bb.addXY(this.cen.x+this.dia/2,this.cen.y+this.dia/2);
		}
		return this.bb;
	}
	copy(that:GemCircle):GemCircle{
		super.copy(that);
		this.cen.copy(that.cen);
		this.dia = that.dia;
		this.innerdia = that.innerdia;
		return this;
	}
	mkcopy():GemCircle{
		return new GemCircle(0,this.par).copy(this);
	}
	rotate(degree:number, rotcen:GemPoint):GemCircle{
		this.cen.rotate(degree,rotcen);
		return this;
	}
	move(dx:number, dy:number):GemCircle{
		this.cen.move(dx,dy);
		return this;
	}
	flip(x0:number):GemCircle{
		this.cen.flip(x0);
		return this;
	}
}
